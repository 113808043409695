<div id="app">
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <a [routerLink]="'/campaign'" class="navbar-brand">The Sanei Telco</a>
    <ul class="navbar-nav mr-auto" routerLinkActive="active">

      <li class="nav-item">
        <a [routerLink]="'/campaign'" class="nav-link" *ngIf="isLoggedIn"  routerLinkActive="active-link">Campagne</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="'/sda'" class="nav-link" *ngIf="isLoggedIn"  routerLinkActive="active-link">Sda</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="'/user'"class="nav-link" *ngIf="isLoggedIn" routerLinkActive="active-link">Utilisateur</a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto" *ngIf="!isLoggedIn">
      <li class="nav-item">
        <a href="/login" class="nav-link" routerLink="login">Connexion</a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn">
      <li class=" headerName">
        <span class="identif">{{ username }}</span>
      </li>
      <li class="nav-item headerName">
        <a [routerLink]="'/api'"class="nav-link" *ngIf="isLoggedIn" routerLinkActive="active-link">API</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="logout()">Deconnexion</a>
      </li>
    </ul>
  </nav>

  <div class="container firstContainer">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="footerVersion">
  {{appVersion}}
  <div class="dev" *ngIf="env.production == false">
    Espace de développement - Ne pas mettre de données client - De données sensibles - De données qui ne peuvent être perdue - ici on écrase sans sommations
  </div>
</div>