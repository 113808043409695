import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'user',     loadChildren: () => import('./board-user/board-user.module').then(m => m.BoardUserModule) },
  { path: 'campaign',  loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule) },
  { path: 'sda',  loadChildren: () => import('./sda/sda.module').then(m => m.SdaModule) },
  { path: 'api',  loadChildren: () => import('./api/api.module').then(m => m.ApiModule) },
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }