import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from './_services/storage.service';
import { AuthService } from './_services/auth.service';
import { UserService } from './_services/user.service';
import { EventBusService } from './_shared/event-bus.service';
import { Router } from  '@angular/router';
import pkg from '../../package.json'
import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public appVersion:any = pkg.version;
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username: string = "";

  eventBusSub?: Subscription;
  env = environment
  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private userService:UserService,
    private eventBusService: EventBusService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = this.storageService.isLoggedIn();
    if (this.isLoggedIn) {


      this.userService.getMe().subscribe({
        next: (data: any) => {
          this.storageService.saveInfoUser(data);
          this.username = data['username']
        },
        error: (err: { error: { message: string; }; }) => {
        }
      });

    }
    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });
  }

  logout(): void {
    this.isLoggedIn = false;
    this.storageService.clean();
    this.router.navigateByUrl('/login').then(() => {
      window.location.reload();
    });

  }
}